//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-778:_6884,_3648,_3156,_8464,_4996,_60,_4620,_5132;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-778')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-778', "_6884,_3648,_3156,_8464,_4996,_60,_4620,_5132");
        }
      }catch (ex) {
        console.error(ex);
      }